import React, { Component, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormGroup, Label, Input } from 'reactstrap';
import AppConfig from 'constants/config'
import axios from 'axios';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Field, Form } from 'react-final-form'
import ReactStrapTextField from '../../components/ReactStrapTextField';
import RenderSelectField from '../../components/reactstrapSelectField';
import { required, number } from 'constants/validations';
import { NotificationManager } from 'react-notifications';
import { getTransactionList, getBuyerTransactionList } from 'actions/admin';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

const monthArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
let date = new Date();
function InsideTHeader(props) {
  const { id } = useParams()
  const [state, setState] = useState({
    open: false,
    options: [],
    name: '',
    bName: '',
    status: '',
    amount: '',
    email: '',
    bal: '',
    phone: '',
    selectData: '',
    month: monthArray[date.getMonth()],
    year: date.getFullYear(),
    otp: '',
    counter: 0,
    narration: '',
    buyerName: '',
    set: false
  })

  const fetchApi = async () => {
    await axios({
      method: 'get',
      url: `${AppConfig.baseUrl}admin/getBuyer?buyerId=${id}`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
    }).then((result) => {
      setState((prev) => ({ ...prev, buyerName: result.data.result }))
    }).catch(error => {
      console.log("error catch", error)
    })
  }
  useEffect(() => {
    fetchApi()
  }, [])

  const onSave = async (values) => {
    await axios({
      method: 'post',
      url: `${AppConfig.baseUrl}admin/creditDebitWallet`,
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('admin_token')
      },
      data: {
        buyerId: id,
        amount: Number(values?.amount),
        status: values?.status,
        otp: Number(values?.otp),
        narration: values?.narration
      }
    }
    ).then(result => {
      console.log("result", result)
      if (result.data.error) {
        NotificationManager.error(result.data.title);
        setState((prev) => ({ ...prev, counter: state.counter + 1 }))
      } else {
        NotificationManager.success(result.data.title);
        handleRequestClose();
        let data = {
          Id: id,
          month: state.month,
          year: state.year,
          page: 1,
          perPage: 20,
          show: props.show
        }
        props.getTransactionList({ data })
        setState((prev) => ({ ...prev, counter: 0 }))
        fetchApi()
      }
    })
      .catch(error => {
        console.log("error catch", error)
      });
  }
  const getOtp = () => {
    setState((prev) => ({ ...prev, set: true }))
    let data = {
      type: 'Transaction',
      action: `Amount ${state.status}`,
      counter: Number(state.counter)
    }
    axios.post(`${AppConfig.baseUrl}systemActivity/addActivity`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);

      }
    })
  }
  const handleRequestClose = () => {
    setState((prev) => ({
      ...prev,
      open: false,
      options: [],
      name: '',
      bName: '',
      status: '',
      amount: 0,
      email: '',
      bal: '',
      phone: '',
      otp: '',
      narration: '',
      selectData: '',
      set: false
    }))
  }
  const openDialog = () => {
    setState((prev) => ({
      ...prev,
      open: true,
      bName: state.buyerName.company_name,
      bal: state.buyerName.wallet_balance,
      phone: state.buyerName.phone,
      email: state.buyerName.email
    }))
  }
  const handleChange = (e, key) => {
    console.log('handleChange', key, e.target.value);
    setState((prev) => ({ ...prev, [key]: e.target.value }));
  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val == key)
    return index > -1 ? true : false
  }
  let { bName, amount, status, email, bal, phone, narration, buyerName, set } = state;
  const { handleSubmit, transactionList } = props;
  console.log('aeofwmsasne', buyerName)

  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  return (
    <div className="page-heading buyerDetailsHeader d-sm-flex justify-content-sm-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">Transactions <ChevronRightIcon /> {buyerName && buyerName.company_name} : MNY {(buyerName && buyerName.wallet_balance).toLocaleString('en-IN')}/-</h2>

      {isButtonAvailable('creditDebitWallet') && <Button variant="outlined" className={'text-primary'} onClick={() => { openDialog() }}>
        Wallet Update
      </Button>}

      <Dialog open={state.open} onClose={handleRequestClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle className='pb-0'>
          Wallet Update
        </DialogTitle>
        <Form
          onSubmit={onSave}
          initialValues={{
            name: state.name,
            narration: '',
            status: '',
            amount: '',
            otp: ''
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} onClose={handleRequestClose}>
              <DialogContent >
                <Row>
                  <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                    <FormGroup className='mb-0'>
                      <Field id="narration" name="narration" type="text" label={'Narration'}
                        component={ReactStrapTextField}
                        validate={required}
                        value={narration}
                        onChange={(e) => handleChange(e, 'narration')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {bName && bName != '' ?
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                        {

                          <FormGroup className='mb-2'>
                            <div style={{ fontSize: 'large', fontWeight: 'bold' }} >{bName}</div>
                          </FormGroup>
                        }
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                        {
                          <FormGroup className='mb-2'>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>Email: {email}</div>
                          </FormGroup>
                        }
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                        {

                          <FormGroup className='mb-2'>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>Phone: {phone}</div>
                          </FormGroup>
                        }
                      </Col>
                      <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                        {
                          <FormGroup className='mb-2'>
                            <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>Current Balance: {bal && (bal).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                          </FormGroup>
                        }
                      </Col>
                    </div>
                    :
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <Col xs={12} md={12} sm={12} xl={12} lg={12}>

                        <FormGroup className='mb-2'>
                          <div style={{ fontSize: 'x-large', fontWeight: 'bold' }}>
                            Select Buyer to update wallet</div>
                        </FormGroup>

                      </Col>
                    </div>
                  }

                  <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                      {
                        <FormGroup>
                          <Field
                            validate={required}
                            type='select'
                            label={'Type'}
                            fullWidth={true}
                            name='status'
                            id='status'
                            component={RenderSelectField}
                          >
                            <option value="" selected={state.status === ''} >
                              Select Type
                            </option>
                            <option value='credit'>Credit </option>
                            <option value='debit'>Debit </option>
                          </Field>
                        </FormGroup>
                      }
                    </Col>
                    <Col xs={12} md={12} sm={12} xl={12} lg={12}>
                      {
                        <FormGroup>
                          <Field type="text" name="amount" id="amount"
                            component={ReactStrapTextField} validate={composeValidators(number, required)} label={'Amount'} onChange={(e) => handleChange(e, 'amount')} value={amount} />
                        </FormGroup>
                      }
                    </Col>
                    <Row>
                      <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingTop: '19px', marginLeft: '15px' }}>
                        <Button onClick={getOtp} color='primary' variant="outlined" fullWidth={true} disabled={set}>
                          Generate OTP
                        </Button>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <FormGroup>
                          <Label className='p-0 m-0'>Enter OTP</Label>
                          <Field id="otp" name="otp" type="text"
                            component={ReactStrapTextField}
                            validate={composeValidators(number, required)} label={"Insert OTP"}
                            onChange={(e) => handleChange(e, 'otp')}
                          />
                        </FormGroup>
                      </Col></Row>
                  </div>
                </Row>
                <DialogActions>
                  <Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
                  <Button type='submit' color='primary'>Update</Button>
                </DialogActions>

              </DialogContent>
            </form>)}
        />
      </Dialog>
    </div>
  )
}

const mapStateToProps = ({ admin }) => {
  const { transactionList, allbuyer, actionList } = admin;
  return { transactionList, allbuyer, actionList }
};

InsideTHeader = connect(
  mapStateToProps,
  {
    getTransactionList,
    getBuyerTransactionList
  }
)(InsideTHeader)

export default InsideTHeader 