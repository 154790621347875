import React, { useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import ContainerHeader from 'components/AdminContainerHeader';
import SalesStatistic from 'components/dashboard/eCommerce/SalesStatistic';
import MonthlySalesStatistic from 'components/dashboard/eCommerce/MonthlySalesStats';
import { getAdminmonthlySalesStats, getAdminDashboardStats, getAdminsalesStats } from 'actions/admin';
import Top5Buyers from './Top5Buyers';
import Top5Sellers from './Top5Sellers';
import CardMenu from 'components/dashboard/Common/CardMenu';
// import IntlMessages from 'util/IntlMessages';
import { connect } from 'react-redux';
import { increamentData } from "./mdata";
import '../../../sellerDetails/SalesStatistic';
import AdminContainer from 'containers/AdminContainer';

let monthNamesForHEad = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const Dashboard = (props) => {
  const [menuState, setMenuState] = useState(false);
  const [dash, setDash] = useState(0);
  const [month, setMonth] = useState(0);
  const [order, setOrder] = useState(0);
  const [signups, setSignups] = useState(0);
  const [year, setYear] = useState(0);
  const [dataForMonthB, setDataForMonthB] = useState(false);
  const [dataForMonthS, setDataForMonthS] = useState(false);
  const [renderYear, setRenderYear] = useState('');
  const [renderMonth, setRenderMonth] = useState("");

  useEffect(() => {
    props.getAdminDashboardStats({});
  }, []);

  const renderYearf = (data) => {
    setRenderYear(data.renderYear);
  };

  const renderMonthf = (data) => {
    setRenderMonth(data.renderMonth);
  };

  const getstatsindex = () => {
    let date = new Date();
    let data = {
      year: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear(),
      graphD: "MonthData"
    };
    let monthData = {
      year: date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear(),
      month: date.getMonth() + 1,
      monthlyCache: "graph_data_daily"
    };
    setRenderYear(date.getMonth() <= 2 ? date.getFullYear() - 1 : date.getFullYear());
    setRenderMonth(monthNamesForHEad[date.getMonth()]);

    props.getAdminmonthlySalesStats(monthData);
    props.getAdminsalesStats(data);

    props.getAdminDashboardStats({ noCache: 'stats' });
    // props.history.push('/admin/dashboard');
  };

  const filterMonthB = () => {
    setDataForMonthS(!dataForMonthS);
  };

  const filterMonthS = () => {
    setDataForMonthB(!dataForMonthB);
  };

  const { adminStats, saleStats, monthlysaleStats } = props;

  return (
    <AdminContainer>
      <div className="dashboard animated slideInUpTiny animation-duration-3">
        <ContainerHeader match={props.match} getStats={getstatsindex} title={"Hi," + ' ' + localStorage.getItem('Name')}>
        </ContainerHeader>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="jr-card jr-card-full">                   <div className={`jr-fillchart bg-success jr-overlay-fillchart`}>
              <div className="card-title mb-3">
                <h3 style={{ color: 'white', marginBottom: '0px' }}>Monthly sales</h3>
              </div>
              <ResponsiveContainer width="100%" height={75}>
                <AreaChart data={increamentData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#3a983e' fill='#3a983e'
                    fillOpacity={1} />
                </AreaChart>
              </ResponsiveContainer>
              <div className="jr-fillchart-content">
                <div className="card-title mb-4">
                  <h3 style={{ color: 'white', marginBottom: '0px' }}>Monthly sales</h3>
                </div>
                <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">&#8377;&nbsp;{(adminStats && adminStats.monthlySales).toLocaleString('en-IN')}</h2>
                <p className="mb-0 jr-fs-sm">
                  <span
                    className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                  </span>
                </p>
              </div>
            </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="jr-card jr-card-full">
              <div className={`jr-fillchart bg-pink accent-2 jr-overlay-fillchart`}>
                <div className="card-title mb-3">
                  <h3 style={{ color: 'white', marginBottom: '0px' }}>Pending orders</h3>
                </div>
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart data={increamentData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#da2361'
                      fill='#da2361'
                      fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
                <div className="jr-fillchart-content">
                  <div className="card-title mb-4">
                    <h3 style={{ color: 'white', marginBottom: '0px' }}>Pending orders</h3>
                  </div>
                  <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{adminStats && adminStats.pendingOrders}</h2>
                  <p className="mb-0 jr-fs-sm">
                    <span
                      className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="jr-card jr-card-full">
              <div className={`jr-fillchart bg-info accent-2 jr-overlay-fillchart`}>
                <div className="card-title mb-3">
                  <h3 style={{ color: 'white', marginBottom: '0px' }}>Sign ups</h3>
                </div>
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart data={increamentData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#0c8e9f' fill='#0c8e9f'
                      fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
                <div className="jr-fillchart-content">
                  <div className="card-title mb-4">
                    <h3 style={{ color: 'white', marginBottom: '0px' }}>Sign ups</h3>
                  </div>
                  <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">{adminStats && adminStats.signups}</h2>
                  <p className="mb-0 jr-fs-sm">
                    <span
                      className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="jr-card jr-card-full">
              <div className={`jr-fillchart bg-indigo accent-2 jr-overlay-fillchart`}>
                <div className="card-title mb-3">
                  <h3 style={{ color: 'white', marginBottom: '0px' }}>Yearly Sales</h3>
                </div>
                <ResponsiveContainer width="100%" height={75}>
                  <AreaChart data={increamentData}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                    <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#273894' fill="#273894"
                      fillOpacity={1} />
                  </AreaChart>
                </ResponsiveContainer>
                <div className="jr-fillchart-content">
                  <div className="card-title mb-4">
                    <h3 style={{ color: 'white', marginBottom: '0px' }}>Yearly Sales</h3>
                  </div>
                  <h2 className="mb-2 jr-fs-xl jr-font-weight-medium">&#8377;&nbsp;{(adminStats && adminStats.yearlySales).toLocaleString('en-IN')}</h2>
                  <p className="mb-0 jr-fs-sm">
                    <span
                      className={`jr-font-weight-medium jr-fs-md jr-chart-up`}>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <SalesStatistic renderYearf={renderYearf} saleStats={saleStats} renderYear={renderYear} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <MonthlySalesStatistic renderMonthf={renderMonthf} renderYearf={renderYearf} monthlysaleStats={monthlysaleStats}
              renderYear={renderYear}
              renderMonth={renderMonth} />
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 " style={{ paddingBottom: 25 }}>
            <div className="jr-card jr-card-widget  card" >
              <Top5Buyers title={'Top 20 Buyers'} />
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
            <div className="jr-card jr-card-widget  card" >
              <Top5Sellers title={'Top 20 Sellers'} />
            </div>
          </div>
        </div>

        <CardMenu menuState={menuState} handleRequestClose={() => setMenuState(false)} />
      </div>
    </AdminContainer>
  );
};

const mapStateToProps = ({ admin }) => {
  const { adminStats, saleStats, monthlysaleStats } = admin;
  return { adminStats, saleStats, monthlysaleStats };
};

export default connect(mapStateToProps, { getAdminDashboardStats, getAdminsalesStats, getAdminmonthlySalesStats })(Dashboard);
