import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import "./index.css"
import AppConfig from 'constants/config'
import '../orderDetails/routes/index.css'
import { Field, Form } from 'react-final-form'
import { required, number, adjustmentAmtS1, adjustmentAmtB1 } from 'constants/validations';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Col, Row, FormGroup, Label, Input } from 'reactstrap';
import ReactstrapSelectField from '../components/reactstrapSelectField';
import ReactStrapTextField from '../components/ReactStrapTextField';
import moment from 'moment';
import AxiosRequest from 'sagas/axiosRequest';
import Drop from '../components/Dropzone';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const TicketDetailsHeader = (props) => {
	const { ticketDetails } = props
	const [newFileUpload, setNewFileUpload] = useState([])
	const [close, setClose] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [walletPopup, setWalletPopup] = useState(false)
	const [addPopup, setAddPopup] = useState(false)
	const [disableFields, setDisableFields] = useState(false)
	const [uploadedFileArr, setUploadedFileArr] = useState([])
	const [loader, setLoader] = useState(false)
	const [openFilter, setOpenFilter] = useState(false)
	const [openUpdate, setOpenUpdate] = useState(false)
	const [buyerData, setBuyerData] = useState(false)
	const [openLightBox, setOpenLightBox] = useState(false)

	const isButtonAvailable = (key) => {
		let index = props.actionList.findIndex((val) => val === key)
		return index > -1 ? true : false
	}

	useEffect(() => {
		setDisableFields((ticketDetails && ticketDetails.sellerStatus === 'open' && ticketDetails.buyerStatus === 'open' && ticketDetails.rtoStatus === 'open') ? false : true)
	}, [ticketDetails])

	const handleRequestClose = () => {
		setOpenFilter(false)
		setOpenUpdate(false)
		setClose(false)
		setNewFileUpload([])
		setLoader(false)
		setUploadedFileArr([])
		setSubmitting(false)
		setWalletPopup(false)
		setAddPopup(false)
	}

	const onUpdate = async (values) => {
		setSubmitting(true)
		setLoader(true)
		let { rtoChargeable, rtoDeliveryDate, rtoAmt, gstOnRto, buyerAdjustmentAmt, sellerAdjustmentAmt, revisedInvoiceAmt } = values;
		let data = {
			Id: ticketDetails._id,
			rtoChargeable: rtoChargeable,
			rtoDeliveryDate: rtoDeliveryDate,
			rtoAmt: rtoAmt,
			gstOnRto: gstOnRto,
			buyerAdjustmentAmt: buyerAdjustmentAmt ? buyerAdjustmentAmt : 0,
			newBuyerAdjustedAmt: rtoChargeable === 'buyer' ? Number(buyerAdjustmentAmt ? buyerAdjustmentAmt : 0) - 212 : buyerAdjustmentAmt ? buyerAdjustmentAmt : 0,
			sellerAdjustmentAmt: sellerAdjustmentAmt ? sellerAdjustmentAmt : 0,
			supportings: newFileUpload,
			revisedInvoiceAmt: revisedInvoiceAmt ? Number(revisedInvoiceAmt) : 0
		}
		hitApi(data);
	}

	const markclose = async (e, id) => {
		let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/changeTicketStatus', '', { id, status: !ticketDetails.isTicketOpen });
		if (result.data.error) {
			NotificationManager.error(result.data.title);
			handleRequestClose();
			props.handleClick();
		} else {
			NotificationManager.success(result.data.title);
			handleRequestClose();
			props.handleClick();
		}
	}


	const hitApi = async (data) => {
		let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/updateTicket', '', data);
		if (result.data.error) {
			NotificationManager.error(result.data.title);
			handleRequestClose();
			props.handleClick();
		} else {
			NotificationManager.success(result.data.title);
			handleRequestClose();
			props.handleClick();
		}
	}

	const onUpdateStaff = async (values) => {
		setSubmitting(true)
		let data = {
			Id: ticketDetails._id,
			sellerStatus: values.sellerStatus,
			buyerStatus: values.buyerStatus,
			rtoStatus: values.rtoStatus,
			staff: 1
		}
		hitApi(data);
	}

	const cancelTicket = async () => {
		let data = { Id: ticketDetails._id };
		let result1 = await AxiosRequest.axiosHelperFunc('post', 'ticket/closeTicket', '', data);
		if (result1.data.error) {
			NotificationManager.error(result1.data.title);
			handleRequestClose();
			props.handleClick();
		} else {
			NotificationManager.success(result1.data.title);
			handleRequestClose();
			props.handleClick();
		}
	}

	const getFiles = async (data1) => {
		setUploadedFileArr([...uploadedFileArr, ...data1]);
		data1.map(data => {
			let reader;
			let file;
			file = data;
			reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async (e) => {
				let joined = [...newFileUpload].concat(e.target.result);
				setNewFileUpload(joined)
			}
		})
	}

	const remove = (file, i) => {
		let index = uploadedFileArr.findIndex(e => e.name === file.name);
		let temp = [...uploadedFileArr]
		temp.splice(index, 1)
		setUploadedFileArr(temp)
		let temp1 = [...newFileUpload]
		temp1.splice(i, 1)
		setNewFileUpload(temp1)
	}

	const openWalletPopup = async () => {
		let result1 = await AxiosRequest.axiosHelperFunc1('get', `ticket/getBuyerData?id=${ticketDetails?.buyer?.buyer_id}`, '', '');
		if (result1.data.data.error) {
		} else {
			setWalletPopup(true)
			setBuyerData(result1.data.data)
		}
	}

	const getOtp = () => {
		setSubmitting(true)
		let data = {
			type: 'Ticket',
			action: `Amount ${ticketDetails && ticketDetails.newBuyerAdjustmentAmt && Number(ticketDetails.newBuyerAdjustmentAmt) > 0 ? 'credit' : 'debit'}`,
			counter: 1
		}
		axios.post(`${AppConfig.baseUrl}systemActivity/addActivity`, data, {
			headers: {
				'Content-Type': 'application/json',
				'token': localStorage.getItem('admin_token')
			}
		}).then((result) => {
			if (result.data.error) {
				NotificationManager.error(result.data.title)
			} else {
				NotificationManager.success(result.data.title);
			}
			setSubmitting(false)
		})
	}

	const onSave = async (values) => {
		setSubmitting(true)
		setLoader(true)
		await axios({
			method: 'post',
			url: `${AppConfig.baseUrl}admin/creditDebitWallet`,
			headers: {
				'Content-Type': 'application/json',
				token: localStorage.getItem('admin_token')
			},
			data: {
				buyerId: ticketDetails?.buyer?.buyer_id,
				amount: Number(values.amount),
				status: values.status,
				otp: Number(values.otp),
				narration: values.narration,
				ticketId: ticketDetails._id
			}
		}).then(result => {
			if (result.data.error) {
				setSubmitting(false)
				NotificationManager.error(result.data.title);
			} else {
				NotificationManager.success(result.data.title);
				handleRequestClose();
				props.handleClick();
				let data = {
					page: 1,
					perPage: 50
				}
				props.getBuyerTransactionList({ data })
			}
		}).catch(error => { });
	}

	const show = () => {
		return (ticketDetails ? ticketDetails.newBuyerAdjustmentAmt ? Math.abs(Number(ticketDetails.newBuyerAdjustmentAmt)) : 0 : 0) === 0 ? true : ticketDetails?.buyerStatus === 'close' ? true : false
	}

	const add = async () => {
		setSubmitting(true)
		setLoader(true);
		let data = { Id: ticketDetails._id, supportings: newFileUpload }
		let result = await AxiosRequest.axiosHelperFunc('post', 'ticket/updateTicket', '', data)
		if (result.data.error) {
			handleRequestClose();
		} else {
			NotificationManager.success(result.data.title)
			handleRequestClose();
			setTimeout(() => props.handleClick(), 1000)
		}
	}

	let images = ticketDetails && ticketDetails.supportings.filter((e) => !e.includes('pdf'));
	let pdfs = ticketDetails && ticketDetails.supportings.filter((e) => e.includes('pdf'));

	const composeValidators = (...validators) => (value, allValues) =>
		validators.reduce((error, validator) => error || validator(value, allValues), undefined)

	return (
		<div className="page-heading buyerDetailsHeader" >
			<div className="d-sm-flex justify-content-sm-end align-items-sm-center" >
				{ticketDetails?.status === 'open' &&
					<div className='status'
						style={{ fontWeight: 600, fontSize: '15px', textAlignLast: 'right' }}
					> MARKCLOSE
						<Switch
							color='primary'
							checked={ticketDetails.isTicketOpen}
							onChange={(e) => markclose(e, ticketDetails._id)}
							aria-label="markclose"
							className="codToggle"
						/>
					</div>
				}
				{images.length > 0 && <>
					<Button variant="outlined" className={'text-success mr-2'} style={{ padding: '2px 15px', display: `block` }} onClick={(e) => setOpenLightBox(true)}>Images	</Button>
					<Lightbox
						open={openLightBox}
						plugins={[Download, Fullscreen, Slideshow, Zoom]}
						styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
						close={() => setOpenLightBox(false)}
						slides={images.map((data, i) => { return { src: data } })}
					/>
				</>
				}
				{
					pdfs.length > 1 ?
						<PopupState>
							{popupState => (
								<React.Fragment>
									<Button variant="outlined" className={'text-success  mr-2'} style={{ padding: '2px 15px' }} {...bindTrigger(popupState)}>View PDFs<KeyboardArrowDownIcon /></Button>
									<Menu {...bindMenu(popupState)} style={{ textAlign: 'end' }}>
										{pdfs.map((data, i) =>
											<a href={data} target='_blank' rel="noreferrer">
												<MenuItem onClick={popupState.close}>{`PDF ${i + 1}`}</MenuItem>
											</a>)
										}
									</Menu>
								</React.Fragment>
							)}
						</PopupState> : pdfs.length === 0 ? '' :
							<a href={pdfs[0]} target='_blank' rel="noreferrer"><Button variant="outlined" className={'text-success  mr-2'} style={{ padding: '2px 15px' }}>View PDF</Button></a>
				}
				{isButtonAvailable('updateOldTicket') && ticketDetails?.type === 'order' && ticketDetails?.status === 'open' ? <>

					<Button variant="outlined" className={`${(ticketDetails?.buyerStatus === 'open' && (ticketDetails ? ticketDetails.newBuyerAdjustmentAmt ? Math.abs(Number(ticketDetails.newBuyerAdjustmentAmt)) : 0 : 0) !== 0) && 'text-success'} mr-2`} style={{ padding: '2px 15px' }} onClick={() => { openWalletPopup() }} disabled={show()}>
						Buyer Wallet
					</Button>
					<Button variant="outlined" className={`${(ticketDetails?.status === 'open' && ticketDetails.isTicketOpen === false) && 'text-success'} mr-2`} style={{ padding: '2px 15px' }} onClick={() => { setOpenFilter(true) }} disabled={ticketDetails?.status === 'close' || ticketDetails.isTicketOpen === true}>
						Update
					</Button>
					<Button variant="outlined" className={`${(ticketDetails?.status === 'open' && ticketDetails.isTicketOpen === false) && 'text-success'}`} style={{ padding: '2px 15px' }} onClick={() => { setOpenUpdate(true) }} disabled={ticketDetails?.status === 'close' || ticketDetails.isTicketOpen === true}>
						Update Status
					</Button>
				</> :
					isButtonAvailable('updateTicketMaker') && ticketDetails?.type === 'order' && ticketDetails?.status === 'open' ?
						<Button variant="outlined" className={`${ticketDetails?.status === 'open' && 'text-success'} mr-2`} style={{ padding: '2px 15px' }} onClick={() => { setOpenFilter(true) }} disabled={ticketDetails?.status === 'close'}>
							Update
						</Button> :
						isButtonAvailable('updateOldTicket') && ticketDetails?.type === 'other' && ticketDetails?.status === 'open' ?
							<>
								<Button variant="outlined" className={`${ticketDetails?.isTicketOpen === false && 'text-success'} mr-2`} style={{ padding: '2px 15px' }} onClick={() => { setAddPopup(true) }} disabled={ticketDetails?.status === 'close' || ticketDetails.isTicketOpen === true}>
									Add files
								</Button>
								<Button variant="outlined" className={`${ticketDetails?.status === 'open' && 'text-danger'}`} style={{ padding: '2px 15px' }} onClick={() => { setClose(true) }} disabled={ticketDetails?.status === 'close'}>
									Close Ticket
								</Button></> :
							(ticketDetails?.type === 'order' && isButtonAvailable('reopenTicket')) ?
								<Button variant="outlined" className={`${'text-success'}`} style={{ padding: '2px 15px' }} onClick={() => { setOpenUpdate(true) }} >
									Update Status
								</Button> : ''
				}
			</div>
			<Dialog open={openFilter} onClose={handleRequestClose} fullWidth={true} maxWidth='md'>
				<DialogTitle className='pb-0'>
					Update Ticket
				</DialogTitle>
				<Form
					onSubmit={onUpdate}
					initialValues={{
						rtoChargeable: ticketDetails && ticketDetails.rtoChargeable ? ticketDetails.rtoChargeable : '',
						rtoDeliveryDate: ticketDetails && ticketDetails.rtoDeliveryDate ? moment(ticketDetails.rtoDeliveryDate).format("YYYY-MM-DD") : '',
						rtoAmt: ticketDetails && ticketDetails.rtoAmt ? ticketDetails.rtoAmt : '',
						gstOnRto: ticketDetails && ticketDetails.gstOnRto ? ticketDetails.gstOnRto : '',
						buyerAdjustmentAmt: ticketDetails && ticketDetails.buyerAdjustmentAmt ? ticketDetails.buyerAdjustmentAmt : 0,
						sellerAdjustmentAmt: ticketDetails && ticketDetails.sellerAdjustmentAmt ? ticketDetails.sellerAdjustmentAmt : 0,
						revisedInvoiceAmt: ticketDetails && ticketDetails.revisedInvoiceAmt ? ticketDetails.revisedInvoiceAmt : 0
					}}
					render={({ handleSubmit, values, form }) => (
						<form onSubmit={handleSubmit} noValidate autoComplete="off">
							<DialogContent >
								{ticketDetails && ticketDetails.type === 'order' ?
									<>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="Tickettype">Ticket type</Label>
													<Input disabled type="select" name="type" value={ticketDetails.type} id="type" >
														<option value="order">Order Related</option>
														<option value="other">Other</option>
													</Input>
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="Issuetype">Issue type</Label>
													<Input disabled type="select" name="issue" value={ticketDetails.issue} id="issue" >
														<option value="">Select issue</option>
														<option value="Short supply">Short supply</option>
														<option value="Damaged products">Damaged products</option>
														<option value="Wrong invoice">Wrong invoice</option>
														<option value="Diff in Invoice and Docket">Diff in Invoice and Docket</option>
														<option value="Short Expiry">Short Expiry</option>
														<option value="Order by mistake">Order by mistake</option>
														<option value="Extra product received">Extra product received</option>
														<option value="Damaged package received">Damaged package received</option>
														<option value="Rescheduling related">Rescheduling related</option>
														<option value="Order not processed">Order not processed</option>
														<option value="Other issue">Other issue</option>
													</Input>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="orderDate">Order Id</Label>
													<Input disabled type="text" name="orderId" value={ticketDetails?.order?.orderNo} id="orderId" />
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="orderDate">Order Date</Label>
													<Input disabled type="text" name="orderDate" value={moment(ticketDetails?.order?.order_id?.createdAt).format('DD/MM/YYYY')} id="orderDate" />
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="buyerName">Buyer Name</Label>
													<Input disabled type="text" name="buyerName" value={ticketDetails?.buyer?.buyerName} id="buyerName" />
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="sellerName">Seller Name</Label>
													<Input disabled type="text" name="sellerName" value={ticketDetails?.seller?.sellerName} id="sellerName" />
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="ogInvoiceAmt">Original Invoice amount</Label>
													<Input disabled type="text" name="ogInvoiceAmt" value={ticketDetails?.orderAmount} id="ogInvoiceAmt" />
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Field id="revisedInvoiceAmt" name="revisedInvoiceAmt" type="text"
														component={ReactStrapTextField} label="Revised Invoice Amount" validate={number}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Field
														name="rtoChargeable"
														component={ReactstrapSelectField}
														id="rtoChargeable"
														disabled={disableFields}
														type="select"
														label='RTO Chargeable'
														onChange={(e) => {
															form.change("rtoChargeable", e.target.value)
															if (e.target.value === 'buyer') {
																form.change("rtoAmt", 212)
																form.change("gstOnRto", 0)
															} else if (e.target.value === 'seller') {
																form.change("rtoAmt", 180)
																form.change("gstOnRto", 32.4)
															} else {
																form.change("rtoAmt", 0)
																form.change("gstOnRto", 0)
															}
														}}
													>
														<option value=" ">Non-chargeable</option>
														<option value="buyer">Buyer</option>
														<option value="seller">Seller</option>
													</Field>
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Field id="rtoDeliveryDate" name="rtoDeliveryDate" type="date"
														component={ReactStrapTextField} label="RTO Delivery date"
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="rtoAmt">RTO Amount</Label>
													<Input disabled type="text" name="rtoAmt" value={values.rtoAmt} id="rtoAmt" />
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Label for="gstOnRto">GST on RTO</Label>
													<Input disabled type="text" name="gstOnRto" value={values.gstOnRto} id="gstOnRto" />
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Field id="buyerAdjustmentAmt" name="buyerAdjustmentAmt" type="text" disabled={disableFields}
														component={ReactStrapTextField} label="Buyer Adjustment Amount" validate={adjustmentAmtB1}
													/>
												</FormGroup>
											</Col>
											<Col md={6} xl={6} xs={6} sm={6} lg={6}>
												<FormGroup>
													<Field id="sellerAdjustmentAmt" name="sellerAdjustmentAmt" type="text" disabled={disableFields}
														component={ReactStrapTextField} label="Seller Adjustment Amount" validate={adjustmentAmtS1}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup >
													<Input label='Issue Description' type='textarea' name='issueDescriptionOR'
														rows="3" value={ticketDetails.comments && ticketDetails.comments[0] && ticketDetails.comments[0].description}
														id="issueDescriptionOR" disabled
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<Drop handleFiles={getFiles} accept="image/*, application/pdf" />
												{uploadedFileArr.length > 0 && <div className="dropzone-content">
													<ul className="mb-2" style={{ listStyleType: 'none' }}>
														{
															uploadedFileArr.map((file, i) => <li key={file.path}>
																<IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
															</li>
															)
														}
													</ul>
												</div>
												}
											</Col>
										</Row>
									</>
									: ticketDetails.type === 'other' ?
										<>
											<Row>
												<Col md={6} xl={6} xs={6} sm={6} lg={6}>
													<FormGroup>
														<Label for="Tickettype">Ticket type</Label>
														<Input disabled type="select" name="type" value={ticketDetails.type} id="type" >
															<option value="order">Order Related</option>
															<option value="other">Other</option>
														</Input>
													</FormGroup>
												</Col>
												<Col md={6} xl={6} xs={6} sm={6} lg={6}>
													<FormGroup>
														<Label for="Issuetype">Issue type</Label>
														<Input disabled type="select" name="issue" value={ticketDetails.issue} id="issue" >
															<option value=" ">Select issue</option>
															<option value="License related">License related</option>
															<option value="Address related">Address related</option>
															<option value="Delivery serviceability related">Delivery serviceability related</option>
															<option value="COD disabled">COD disabled</option>
															<option value="Delivery charges activated">Delivery charges activated</option>
															<option value="Other issue">Other issue</option>
														</Input>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col>
													<FormGroup >
														<Input label='Issue Description' type='textarea' name='issueDescriptionO'
															rows="3" value={ticketDetails.comments && ticketDetails.comments[0] && ticketDetails.comments[0].description}
															id="issueDescriptionO" disabled
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col>
													<Drop handleFiles={getFiles} accept="image/*" />
												</Col>
											</Row>
										</> :
										<div style={{ fontSize: 'larger' }}> Please select ticket type</div>
								}
								<DialogActions>
									<Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
									{(ticketDetails.type === 'order' && !submitting && !loader) ?
										<Button type='submit' color='primary' disabled={submitting}>Update</Button> :
										loader ? <CircularProgress size={30} /> :
											<Button onClick={() => handleRequestClose()} color='primary'>Update</Button>
									}
								</DialogActions>
							</DialogContent>
						</form>
					)}
				/>
			</Dialog>
			<Dialog open={openUpdate} onClose={handleRequestClose} fullWidth={true} maxWidth='md'>
				<DialogTitle className='pb-0'>
					Update Ticket
				</DialogTitle>
				<Form
					onSubmit={onUpdateStaff}
					initialValues={{
						sellerStatus: ticketDetails ? ticketDetails.sellerStatus : '',
						buyerStatus: ticketDetails ? ticketDetails.buyerStatus : '',
						rtoStatus: ticketDetails ? ticketDetails.rtoStatus : '',
					}}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit} noValidate autoComplete="off">
							<DialogContent >
								<Row>
									<Col md={6} xl={6} xs={6} sm={6} lg={6}>
										<FormGroup>
											<Field
												name="buyerStatus"
												component={ReactstrapSelectField}
												id="buyerStatus"
												type="select"
												label='Buyer status'
											>
												<option value="">Select status</option>
												<option value="open">Open</option>
												<option value="close">Close</option>
											</Field>
										</FormGroup>
									</Col>
									<Col md={6} xl={6} xs={6} sm={6} lg={6}>
										<FormGroup >
											<Field
												name="sellerStatus"
												component={ReactstrapSelectField}
												id="sellerStatus"
												type="select"
												label='Seller status'
											>
												<option value="">Select status</option>
												<option value="open">Open</option>
												<option value="close">Close</option>
											</Field>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={6} xl={6} xs={6} sm={6} lg={6}>
										<FormGroup >
											<Field
												name="rtoStatus"
												component={ReactstrapSelectField}
												id="rtoStatus"
												type="select"
												label='RTO status'
											>
												<option value="">Select status</option>
												<option value="open">Open</option>
												<option value="close">Close</option>
											</Field>
										</FormGroup>
									</Col>
								</Row>
								<DialogActions>
									<Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
									<Button type='submit' color='primary' disabled={submitting}>Update</Button>
								</DialogActions>
							</DialogContent>
						</form>
					)}
				/>
			</Dialog>
			<Dialog open={close} onClose={handleRequestClose} fullWidth={true} maxWidth='sm'>
				<DialogTitle className='pb-0'>
					Close Ticket
				</DialogTitle>
				<DialogContent >
					Are you sure, you want to close this ticket ?
					<DialogActions>
						<Button onClick={() => handleRequestClose()} color='secondary'>No</Button>
						<Button onClick={() => cancelTicket()} color='primary'>Yes</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<Dialog open={walletPopup} onClose={handleRequestClose} fullWidth={true} maxWidth={'md'}>
				<DialogTitle className='pb-0'>
					Wallet Update
				</DialogTitle>
				<Form
					onSubmit={onSave}
					initialValues={{
						amount: ticketDetails ? ticketDetails.newBuyerAdjustmentAmt ? Math.abs(Number(ticketDetails.newBuyerAdjustmentAmt)) : 0 : 0,
						status: ticketDetails && ticketDetails.newBuyerAdjustmentAmt && Number(ticketDetails.newBuyerAdjustmentAmt) > 0 ? 'credit' : 'debit',
						otp: '',
						narration: `Adjustment against ticket ${ticketDetails && ticketDetails.ticketNo}`,
					}}
					render={({ handleSubmit, values }) => (
						<form onSubmit={handleSubmit} onClose={handleRequestClose}>
							<DialogContent className='pt-0'>
								<Row>
									<Col xs={12} md={12} sm={12} xl={12} lg={12}>
										<FormGroup>
											<Label for="name">Buyer Name</Label>
											<Input disabled type="text" name="name" value={ticketDetails?.buyer?.buyerName} id="name" />
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={12} md={12} sm={12} xl={12} lg={12}>
										<FormGroup>
											<Label for="Narration">Narration</Label>
											<Input disabled type="text" name="Narration" value={values.narration} id="Narration" />
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										<Col xs={12} md={12} sm={12} xl={12} lg={12}>
											{
												<FormGroup className='mb-2'>
													<div style={{ fontSize: 'large', fontWeight: 'bold' }} >{buyerData?.company_name}</div>
												</FormGroup>
											}
										</Col>
										<Col xs={12} md={12} sm={12} xl={12} lg={12}>
											{
												<FormGroup className='mb-2'>
													<div style={{ fontSize: 'medium', fontWeight: 'bold' }}>Email: {buyerData?.email}</div>
												</FormGroup>
											}
										</Col>
										<Col xs={12} md={12} sm={12} xl={12} lg={12}>
											{
												<FormGroup className='mb-2'>
													<div style={{ fontSize: 'medium', fontWeight: 'bold' }}>Phone: {buyerData?.phone}</div>
												</FormGroup>
											}
										</Col>
										<Col xs={12} md={12} sm={12} xl={12} lg={12}>
											{
												<FormGroup className='mb-2'>
													<div style={{ fontSize: 'medium', fontWeight: 'bold' }}>Current Balance: {buyerData?.bal && (buyerData?.bal).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
												</FormGroup>
											}
										</Col>
									</div>
									<div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										<Col xs={12} md={12} sm={12} xl={12} lg={12}>
											{
												<FormGroup>
													<Label for="Type">Type</Label>
													<Input disabled type="select" name="walletStatus" value={values.status} id="walletStatus" >
														<option value='credit'>Credit</option>
														<option value='debit'>Debit</option>
													</Input>
												</FormGroup>
											}
										</Col>
										<Col xs={12} md={12} sm={12} xl={12} lg={12}>
											{
												<FormGroup>
													<Label for="amount">Amount</Label>
													<Input disabled type="text" name="amount" value={values.amount} id="amount" />
												</FormGroup>
											}
										</Col>
										<Row>
											<Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingTop: '19px', marginLeft: '15px' }}>
												<Button onClick={getOtp} color='primary' variant="outlined" fullWidth={true} disabled={submitting}>
													Generate OTP
												</Button>
											</Col>
											<Col xs={6} sm={6} md={6} lg={6} xl={6}>
												<FormGroup>
													<Label className='p-0 m-0'>Enter OTP</Label>
													<Field id="otp" name="otp" type="text"
														component={ReactStrapTextField}
														validate={composeValidators(number, required)} label={"Insert OTP"}
													/>
												</FormGroup>
											</Col></Row>
									</div>
								</Row>
								<DialogActions>
									<Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
									<Button type='submit' color='primary' disabled={submitting}>Update</Button>
								</DialogActions>
							</DialogContent>
						</form>
					)}
				/>
			</Dialog>
			{/* -----------add images------------- */}
			<Dialog open={addPopup} onClose={handleRequestClose} fullWidth={true} maxWidth='md'>
				<DialogTitle>
					Add Files
				</DialogTitle>
				<DialogContent>
					<Row>
						<Col>
							<Drop handleFiles={getFiles} accept="image/*, application/pdf" />
							{uploadedFileArr.length > 0 && <div className="dropzone-content">
								<ul className="mb-2" style={{ listStyleType: 'none' }}>
									{
										uploadedFileArr.map((file, i) => <li key={file.path}>
											<IconButton className='clear mr-2'><ClearIcon onClick={(e) => remove(file, i)} /></IconButton>{file.path} - {file.size} bytes
										</li>
										)
									}
								</ul>
							</div>
							}
						</Col>
					</Row>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleRequestClose()} color='secondary'>Cancel</Button>
					{(newFileUpload.length !== 0 && !submitting && !loader) ?
						<Button onClick={() => add()} color='primary' disabled={submitting}>Add</Button> :
						loader ? <CircularProgress size={30} /> :
							<Button disabled={true} color='primary'>Add</Button>
					}
				</DialogActions>
			</Dialog>
		</div >
	)
}

const mapStateToProps = ({ admin }) => {
	const { actionList } = admin;
	return { actionList }
}
export default connect(mapStateToProps, {})(TicketDetailsHeader)
