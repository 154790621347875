import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import OrderDetailsHeader from './OrderDetailsHeader';
import { Col, Row, Label, FormGroup } from 'reactstrap';
import OrderDetailsTable from "./orderDetailTable";
import { getOrderDetails } from 'actions/admin';
import { connect } from "react-redux";
import moment from 'moment'
import AppConfig from '../../../../constants/config'
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactStrapTextField from '../../components/ReactStrapTextField';
import ReactstrapSelectField from '../../components/reactstrapSelectField';
import { Field, Form } from 'react-final-form'
import { required, number, prepaidOrder } from '../../../../constants/validations';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router';
import AdminContainer from 'containers/AdminContainer';

function OrderDetails(props) {
  const { id } = useParams()
  const history = useNavigate()
  const [state, setState] = useState({
    updatedProduct: [],
    status: 'New',
    orderDetailsState: {},
    statusBadge: ' ',
    totalValue: 0,
    finalfinal: 0,
    open: false,
    newData: '',
    counter: 0,
    set: false,
    log: false,
    isSuperAdmin: "",
  })

  useEffect(() => {
    props.getOrderDetails({ history: history, data: { orderId: id } })
  }, [])


  useEffect(() => {
    if (Object.keys(props.orderDetails || {})?.length > 0) {
      let tempStatusBadge = props?.orderDetails?.requested === props?.orderDetails?.order_status[props?.orderDetails?.order_status?.length - 1].status ? props?.orderDetails?.requested : props?.orderDetails?.requested === "Requested" ? "Requested" : props?.orderDetails?.requested === "Approved" ? "Approved" : props?.orderDetails?.order_status[props?.orderDetails?.order_status?.length - 1].status;
      setState((prev) => ({ ...prev, status: props.orderDetails.requested, orderDetailsState: props.orderDetails, statusBadge: tempStatusBadge, totalValue: props.totalValue }));
    } else if (props.orderDetails && Object.keys(props.orderDetails).length > 0) {
      let tempStatusBadge = props.orderDetails.requested === props.orderDetails.order_status[props.orderDetails.order_status.length - 1].status ? props.orderDetails.requested : props.orderDetails.requested === 'Requested' ? 'Requested' : props.orderDetails.requested === 'Approved' ? 'Approved' : props.orderDetails.order_status[props.orderDetails.order_status.length - 1].status;
      setState((prev) => ({
        ...prev,
        status: props.orderDetails.requested,
        orderDetailsState: props.orderDetails,
        statusBadge: tempStatusBadge,
        totalValue: props.totalValue,
        isSuperAdmin: props.userName

      }));
    }
  }, [props.orderDetails, props.totalValue, props.userName])

  const getValue = (data) => {
    if (!data.discount_on_product && data.discount_per > 0) {
      let totalPtr = (data.PTR - ((data.PTR / 100) * data.discount_per))
      return totalPtr * Number(data.quantity)
    } else {
      return data.PTR * Number(data.quantity)
    }
  }

  const getFinalValue = (data) => {
    let tempValue = getValue(data)
    let finalValue = ((data.inventory_id.product_id.GST.value / 100) * tempValue) + tempValue;
    return finalValue
  }
  const handleRequestClose = () => {
    setState((prev) => ({ ...prev, open: false, set: false }));
  }
  const handleLogClose = () => {
    setState((prev) => ({ ...prev, log: false }));
  }

  const editOrder = () => {
    setState((prev) => ({
      ...prev,
      open: true,
    }))

  }

  const onClickHandle = (e, value, orderProductData) => {
    e.preventDefault();
    if (orderProductData !== '') {
      this.setState((prev) => ({ ...prev, updatedProduct: orderProductData }))
    }
  }
  const getOtp = async () => {
    await setState((prev) => ({ ...prev, set: true }))
    let data = {
      type: 'Order',
      action: 'Order change',
      counter: Number(state.counter),
      typeId: props.orderDetails._id
    }
    axios.post(`${AppConfig.baseUrl}systemActivity/addActivity`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
      } else {
        NotificationManager.success(result.data.title);

      }
    })
  }
  const onSave = async (values) => {
    console.log(values)
    let data = {
      orderId: props.orderDetails._id,
      amount: Number(values.amount),
      type: values.payType === 'Bulk Prepaid' ? 'Online' : values.payType,
      otp: Number(values.otp),
      isBulk: values.payType === 'Bulk Prepaid' ? true : false,
    }
    await axios.post(`${AppConfig.baseUrl}order/changeOrder`, data, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('admin_token')
      }
    }).then((result) => {
      if (result.data.error) {
        NotificationManager.error(result.data.title)
        setState((prev) => ({ ...prev, counter: state.counter + 1 }))
      } else {
        NotificationManager.success(result.data.title);
        props.getOrderDetails({ history: props.history, data: { orderId: id } })
        setState((prev) => ({ ...prev, counter: 0 }))
      }
    })
    setState((prev) => ({ ...prev, open: false, set: false }))
  }
  const isButtonAvailable = (key) => {
    let index = props.actionList.findIndex((val) => val === key)
    return index > -1 ? true : false
  }

  let { status, orderDetailsState, set } = state;
  const { orderDetails } = props;
  let finalGrand = 0;
  orderDetails && orderDetails.products && orderDetails.products.map((eachProduct) => {
    return (
      finalGrand = finalGrand + Number(getFinalValue(eachProduct))

    )
  })
  const composeValidators = (...validators) => (value, allValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined)

  return (
    <AdminContainer>
      <React.Fragment>
        <div className="order-details" >

        </div>
        {(orderDetailsState && Object.keys(orderDetailsState).length > 0) ?
          <div className="dashboard animated slideInUpTiny animation-duration-3">
            <OrderDetailsHeader
              isSuperAdmin={props.userName}
              status={status}
              updatedProduct={state.updatedProduct}
              match={props.match}
              title={`Order ID`}
            />
            <div className="row">
              <div className="col-12">
                <div className="jr-card chart-user-statistics bg-primary darken-4 text-white">
                  <div className="orderDetails px-4 mb-4">
                    <Row className="pb-4">
                      <Col xs={12} xl={3} sm={12} md={5} lg={5} className="orderDetailsMainCol">
                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> ORDER PLACED BY</h3>
                        <div className="p-1">
                          <i className="zmdi zmdi-info-outline zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {id}
                          </span>
                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-account-o zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.company_name : 'N/A'
                            }
                          </span>
                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-pin zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.user_address : 'N/A'
                            }
                          </span>

                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-city zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.user_id && orderDetailsState.user_id.user_city !== undefined ? orderDetailsState.user_id.user_city : ''
                            },&nbsp;
                            {
                              (orderDetailsState.user_id !== undefined && orderDetailsState.user_id.user_state !== undefined) ? orderDetailsState.user_id.user_state.name : 'N/A'
                            }
                          </span>
                          <p className="ml-4 mb-0">
                            {
                              orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.user_pincode : 'N/A'
                            }
                          </p>
                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.user_id !== undefined ? orderDetailsState.user_id.phone : 'N/A'
                            }
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} xl={3} sm={12} md={5} lg={5} className="orderDetailsMainCol">
                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}> ORDER PLACED TO</h3>
                        <div className="p-1">
                          <i className="zmdi zmdi-account-o zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.seller_id !== undefined ? orderDetailsState.seller_id.company_name : 'N/A'
                            }
                          </span>
                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-pin zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.seller_id !== undefined ? orderDetailsState.seller_id.user_address : 'N/A'
                            }
                          </span>
                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-city zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              (orderDetailsState.seller_id !== undefined && orderDetailsState.seller_id.user_state !== undefined) ? orderDetailsState.seller_id.user_state.name : 'N/A'
                            }
                          </span>
                          <p className="ml-4 mb-0">
                            {
                              orderDetailsState.seller_id !== undefined ? orderDetailsState.seller_id.user_pincode : 'N/A'
                            }
                          </p>
                        </div>
                        <div className="p-1">
                          <i className="zmdi zmdi-phone zmdi-hc-fw zmdi-hc-sm text-white align-self-center" />
                          <span className="align-self-center ml-1">
                            {
                              orderDetailsState.seller_id !== undefined ? orderDetailsState.seller_id.phone : 'N/A'
                            }
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} xl={3} sm={4} md={2} lg={2} className="orderDetailsMainCol">
                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}>BUYER KYC DETAILS</h3>

                        {orderDetailsState.user_id && orderDetailsState.user_id.drugLic20B && orderDetailsState.user_id.drugLic20B.name ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              FORM 20 <span className="small">&#40;{orderDetailsState.user_id && orderDetailsState.user_id.drugLic20B && orderDetailsState.user_id.drugLic20B.expires ? `${orderDetailsState.user_id.drugLic20B.lic ? orderDetailsState.user_id.drugLic20B.lic : ''} \u007C ` + moment(orderDetailsState.user_id.drugLic20B.expires).format('DD/MM/YYYY') : ''}&#41;</span>
                            </span>
                            <span className="align-self-center ml-2 pull-right">
                              <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.drugLic20B && orderDetailsState.user_id.drugLic20B.name ? orderDetailsState.user_id.drugLic20B.name : ''}`}>
                                <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                              </a>
                            </span>
                          </div>
                          : ''}

                        {orderDetailsState.user_id && orderDetailsState.user_id.drugLic21B && orderDetailsState.user_id.drugLic21B.name ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              FORM 21 <span className="small">&#40;{orderDetailsState.user_id && orderDetailsState.user_id.drugLic21B && orderDetailsState.user_id.drugLic21B.expires ? `${orderDetailsState.user_id.drugLic21B.lic ? orderDetailsState.user_id.drugLic21B.lic : ''} \u007C ` + moment(orderDetailsState.user_id.drugLic21B.expires).format('DD/MM/YYYY') : ''}&#41;</span>
                            </span>
                            <span className="align-self-center ml-2 pull-right">
                              <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.drugLic21B && orderDetailsState.user_id.drugLic21B.name ? orderDetailsState.user_id.drugLic21B.name : ''}`}>
                                <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                              </a>
                            </span>
                          </div>
                          : ''}

                        {orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && orderDetailsState.user_id.fassaiLic.name ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              FASSAI
                              {orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && <span className="small">&#40;{orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && orderDetailsState.user_id.fassaiLic.expires ? `${orderDetailsState.user_id.fassaiLic.lic ? orderDetailsState.user_id.fassaiLic.lic : ""} \u007C ` + moment(orderDetailsState.user_id.fassaiLic.expires).format('DD/MM/YYYY') : ''}&#41;</span>}
                            </span>

                            <span className="align-self-center ml-2 pull-right">
                              <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.fassaiLic && orderDetailsState.user_id.fassaiLic.name ? orderDetailsState.user_id.fassaiLic.name : ''}`}>
                                <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                              </a>
                            </span>

                          </div>
                          : ''}

                        {orderDetailsState.user_id && orderDetailsState.user_id.gstLic && orderDetailsState.user_id.gstLic.name ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              GSTN
                              {orderDetailsState.user_id && orderDetailsState.user_id.gstLic && <span className="small">&#40;
                                {orderDetailsState.user_id && orderDetailsState.user_id.gstLic && orderDetailsState.user_id.gstLic.lic ? orderDetailsState.user_id.gstLic.lic : ""}
                                &#41;</span>
                              }
                            </span>
                            <span className="align-self-center ml-2 pull-right">
                              <a target='blank' download href={`${AppConfig.baseUrl}users/${orderDetailsState.user_id && orderDetailsState.user_id.gstLic && orderDetailsState.user_id.gstLic.name ? orderDetailsState.user_id.gstLic.name : ''}`}>
                                <i className="zmdi zmdi-cloud-download zmdi-hc-fw zmdi-hc-md text-white align-self-center" />
                              </a>
                            </span>
                          </div>
                          : ''}
                        <div className="p-1">
                          <span style={{ fontSize: 15, paddingLeft: '4px', marginBottom: '0px', marginTop: '8px' }}>PAYMENT TYPE &#40;{orderDetails && orderDetails?.isBulk ? 'Bulk Prepaid' : (orderDetails?.paymentType === 'Online') ?
                            `Prepaid${orderDetails?.paymentType === "Online" && orderDetails?.paymentStatus === "success" && orderDetails?.requested !== "Cancelled" && orderDetails?.rawTransaction && orderDetails?.rawTransaction?.payment_mode ? "-" + orderDetails?.rawTransaction?.payment_mode : ""}` : orderDetails && orderDetails?.paymentType}&#41;</span>
                          {(status === 'Requested' || status === 'Delivered' || status === 'Cancelled') ? '' :
                            isButtonAvailable('changeOrder') &&
                            <span className="align-self-center ml-2 pull-right">
                              {orderDetails?.paymentStatus !== 'pending' ? <SettingsIcon style={{ fontSize: 'medium' }} onClick={editOrder} disabled={orderDetails?.paymentStatus === 'pending' ? true : false} /> : ''}</span>}
                        </div>
                        {(orderDetailsState && Number(orderDetailsState.delivery_charges) > 0) ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              Delivery Charges ₹{(orderDetailsState && orderDetailsState.delivery_charges)}
                            </span>
                          </div> : ''}
                        <div className="p-1">
                          <span className="align-self-center ml-1">
                            Total ₹ {(orderDetailsState && orderDetailsState.total_amount).toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                          </span>
                          {(status === 'Requested' || status === 'Delivered' || status === 'Cancelled') ? '' :
                            isButtonAvailable('changeOrder') &&
                            <span className="align-self-center ml-2 pull-right">
                              {orderDetails?.paymentStatus !== 'pending' ? <SettingsIcon style={{ fontSize: 'medium' }} onClick={editOrder} /> : ''}</span>}
                        </div>
                      </Col>
                      <Col xs={12} xl={3} sm={4} md={2} lg={2} className="orderDetailsMainCol">
                        <h3 style={{ fontSize: 15, paddingLeft: '7px' }}>ORDER INSIGHT
                        </h3>
                        <div className="p-1">
                          <span className="align-self-center ml-1">
                            <Button style={{ backgroundColor: '#fff' }} variant="contained" className={'text-primary'} onClick={() => { setState((prev) => ({ ...prev, log: true })) }}>Order Logs</Button>
                          </span>
                        </div>
                        {orderDetailsState.createdAt ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              Placed On:
                            </span>&nbsp;
                            <span className="align-self-center">
                              {moment(orderDetailsState.createdAt).format('DD/MM/YYYY hh:mm A')}
                            </span>
                          </div>
                          : ''}

                        {orderDetailsState.unique_invoice ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              Unique Invoice:
                            </span>&nbsp;
                            <span className="align-self-center">
                              {orderDetailsState.unique_invoice}
                            </span>
                          </div>
                          : ''}

                        {orderDetailsState.seller_invoice ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              Seller Invoice:
                            </span>&nbsp;
                            <span className="align-self-center">
                              {orderDetailsState.seller_invoice}
                            </span>
                          </div>
                          : ''}

                        {orderDetailsState.requested ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              Order Status:
                            </span>&nbsp;
                            <span className="align-self-center">
                              {orderDetailsState.requested}
                            </span>
                          </div>
                          : ''}

                        {orderDetailsState.isBulk ?
                          <div className="p-1">
                            <span className="align-self-center ml-1">
                              Bulk Prepaid
                            </span>
                          </div>
                          : ''}
                      </Col>
                    </Row>

                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <OrderDetailsTable
                  onClickHandle={onClickHandle}
                />
              </div>
            </div>
          </div>
          : <div className="loader-view">
            <CircularProgress />
          </div>
        }

        {/* -------------=-=-=-=-=-=======edit payment and amount===============----------------- */}

        <Dialog open={state.open} onClose={handleRequestClose}
          // fullWidth={true}
          maxWidth={'sm'}>
          <Form
            onSubmit={onSave}
            initialValues={{
              payType: (orderDetails?.paymentType === 'Online' && orderDetails?.isBulk) ? 'Bulk Prepaid' : orderDetails?.paymentType,
              amount: orderDetails?.total_amount,
              otp: '',
              invoice2: orderDetails?.total_amount,
            }}
            render={({ handleSubmit, errors, values }) => {
              console.log(errors, values)
              return <form onSubmit={handleSubmit}>
                <DialogTitle>
                  Change Order
                </DialogTitle>
                <DialogContent>
                  <React.Fragment>
                    <FormGroup>
                      <Field id="invoice2" name="invoice2" type="text" hide={true}
                        component={ReactStrapTextField}
                      />
                      <Field
                        name="payType"
                        component={ReactstrapSelectField}
                        label="Payment Method"
                        validate={required}
                        id="payType"
                        type="select"
                      > <option value="">Select Payment Type</option>
                        <option value="Bulk Prepaid">Bulk Prepaid</option>
                        <option value="COD">COD</option>
                        <option value="Online">Prepaid</option>
                      </Field>
                    </FormGroup>
                    <FormGroup>
                      <Field id="amount" name="amount" type="text"
                        component={ReactStrapTextField} label={"Total amount"}
                        validate={orderDetails.paymentType === 'Online' ? composeValidators(number, required, prepaidOrder) : composeValidators(number, required)}
                      />
                    </FormGroup>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingTop: '19px' }}>
                        <Button onClick={getOtp} color='primary' variant="outlined" fullWidth={true} disabled={set}>
                          Generate OTP
                        </Button>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <FormGroup>
                          <Label className='p-0 m-0'>Enter OTP</Label>
                          <Field id="otp" name="otp" type="text"
                            component={ReactStrapTextField}
                            validate={composeValidators(number, required)} label={"Insert OTP"}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </React.Fragment>
                </DialogContent>
                <DialogActions className='pr-3'>
                  <Button onClick={handleRequestClose} color='secondary' >
                    Cancel
                  </Button>
                  {values.otp && values.otp.length === 6 ?
                    <Button type='submit' color='primary'>
                      Update
                    </Button>
                    :
                    <Button color='primary'>
                      Insert OTP
                    </Button>
                  }
                </DialogActions>
              </form>
            }}
          />

        </Dialog>
        {/* ---------------------Order log --------------------- */}
        <Dialog open={state.log} onClose={handleLogClose} fullWidth={true} maxWidth={'md'}>
          <DialogTitle>
            Order Logs
          </DialogTitle>
          <DialogContent>
            <Paper className={'tableRoot'}>
              {orderDetailsState.order_status && orderDetailsState.order_status.length > 0 ?
                <Table className={'tableMain'} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" size='small'>No.</TableCell>
                      <TableCell align="center" size='small'>Date</TableCell>
                      <TableCell align="center" size='small'>Status</TableCell>
                      <TableCell align="center" size='small'>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetailsState.order_status.map((eachStatus, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" size='small'>{index + 1}</TableCell>
                        <TableCell align="center" size='small'>{moment(eachStatus.date).format('DD/MM/YYYY hh:mm A')}</TableCell>
                        <TableCell align="center" size='small'>{eachStatus.status}</TableCell>
                        <TableCell align="center" size='small'>{eachStatus.admin_description ? eachStatus.admin_description : eachStatus.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                : 'No Orders status Found'}
            </Paper>
            {orderDetailsState.requested === 'Cancelled' && orderDetailsState.cancelledBy && orderDetailsState.cancelledBy.user_type === 'seller' && <>
              <h3 className='mt-3 mb-2'>Removed {orderDetailsState.removedProd && orderDetailsState.removedProd.length > 1 ? 'Products' : 'Product'}:</h3>
              {orderDetailsState && orderDetailsState.removedProd.length > 0 && orderDetailsState.removedProd.map((data, index) => {
                return <h5 className='text-muted mb-2'> {++index}. {data.name} </h5>
              })
              } </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogClose} color='secondary' >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </AdminContainer>
  );
}


const mapStateToProps = ({ admin }) => {
  const { orderDetails, totalValue, actionList, userName } = admin;
  return { orderDetails, totalValue, actionList, userName }
};

OrderDetails = connect(mapStateToProps, { getOrderDetails })(OrderDetails);

export default OrderDetails 